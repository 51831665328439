import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { FormChanges } from '@core/models/form-changes.model';
import { AppContentService } from '@core/services/static/app-content.service';

@Injectable({
  providedIn: 'root',
})
export class ResetContentGuard implements CanDeactivate<FormChanges> {
  canDeactivate(): boolean {
    AppContentService.resetContent();

    return true;
  }
}
