import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  private readonly progressBarSubject$ = new Subject<boolean>();

  private count = 0;

  get isShowProgressBar$(): Observable<boolean> {
    return this.progressBarSubject$.asObservable();
  }

  showProgressBar(show: boolean): void {
    if (show) {
      this.count++;
    } else {
      this.count--;
    }

    show = this.count > 0;

    this.progressBarSubject$.next(show);
  }
}
