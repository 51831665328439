import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PagingResponse } from '@avenir-client-web/models';
import { IInfiniteScrollEvent } from 'ngx-infinite-scroll';
import { FilterOptionValue } from '../enums/search.enums';
import {
  FilterOption,
  SearchResult,
  ShownSearchResultButton,
} from '../interfaces/search.interfaces';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchResultsComponent implements OnChanges {
  @Input() data: SearchResult[];

  @Input() currentPage: PagingResponse<SearchResult>;

  @Input() option: FilterOption<FilterOptionValue>;

  @Input() isAllTab = false;

  @Output() scrollDown = new EventEmitter<IInfiniteScrollEvent>();

  @Output() showAll = new EventEmitter<FilterOptionValue>();

  defaultFilter = FilterOptionValue.ALL;

  isShownMore = false;

  showItemButtons: ShownSearchResultButton[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.option || changes?.currentPage) {
      this.showItemButtons = [
        {
          label: this.getButtonLabel($localize`default.action.showmore`),
          show: () => this.showMoreItems(),
          icon: 'pi pi-angle-down',
          iconPos: 'right',
          styleClass: 'bg-none bd-primary',
          visible: this.data?.length < this.currentPage?.data?.length,
        },
        {
          label: this.getButtonLabel($localize`default.action.showall`, true),
          show: () => this.showAllItems(),
          icon: 'pi pi-angle-right',
          iconPos: 'right',
          styleClass: 'bd-primary',
          visible: true,
        },
      ];
    }
  }

  showMoreItems(): void {
    this.isShownMore = !this.isShownMore;

    if (this.isShownMore) {
      this.data = this.currentPage.data;
      this.showItemButtons[0].label = this.getButtonLabel(
        $localize`default.action.showless`
      );
      this.showItemButtons[0].icon = 'pi pi-angle-up';
    } else {
      this.data = this.currentPage.data.slice(0, this.currentPage.pageSize / 2);
      this.showItemButtons[0].label = this.getButtonLabel(
        $localize`default.action.showmore`
      );
      this.showItemButtons[0].icon = 'pi pi-angle-down';
    }
  }

  showAllItems(): void {
    this.showAll.emit(this.option.value);
  }

  private getButtonLabel(translatedLabel: string, isShownAll = false): string {
    if (this.option?.value === FilterOptionValue.NEWS && isShownAll)
      return $localize`default.action.showallnews`;

    const label =
      this.option?.value !== FilterOptionValue.ELEARNING
        ? this.option?.label
        : $localize`default.label.elearnings`;

    return label ? translatedLabel.replace('[type]', label) : translatedLabel;
  }
}
