import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  extractDocumentUrl,
  extractFileInfoFromUrl,
  FileExtension,
  FileResponseType,
  FileService,
  isInternalUrl,
  shouldPreview,
} from '@avenir-client-web/file';
import { Subject, takeUntil } from 'rxjs';

enum LinkTargetType {
  BLANK = '_blank',
  SELF = '_self',
}

@Directive({
  selector: '[appLinkActionHandler]',
})
export class LinkActionHandlerDirective implements OnDestroy {
  @Input() appLinkActionHandler: string;

  @Input() keywords: string[] = [];

  @Input() description: string;

  @Input() id: number;

  private readonly compUnsubscribe$ = new Subject<void>();

  @HostListener('click', ['$event'])
  onClick(): void {
    this.linkActionHandler();
  }

  constructor(
    private readonly fileService: FileService,
    private readonly router: Router
  ) {}

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  private linkActionHandler(): void {
    if (!this.appLinkActionHandler) {
      return;
    }

    if (isInternalUrl(this.appLinkActionHandler)) {
      const documentUrlParts = extractDocumentUrl(this.appLinkActionHandler);

      // Open internal link
      if (documentUrlParts?.length !== 2) {
        return this.openLink(LinkTargetType.SELF);
      }

      const extension = this.appLinkActionHandler.split('.').pop();

      // Open document link without filename
      if (!Object.values(FileExtension).includes(extension as FileExtension))
        return this.openLink(LinkTargetType.BLANK);

      const [, fileId, fileName] = extractFileInfoFromUrl(
        this.appLinkActionHandler
      );

      if (shouldPreview(extension as FileExtension)) {
        return this.openLink(LinkTargetType.BLANK);
      }

      return this.downloadFile(
        fileId,
        decodeURIComponent(fileName),
        extension as FileExtension
      );
    } else {
      return this.openLink(LinkTargetType.BLANK);
    }
  }

  private downloadFile(
    fileId: string,
    fileName: string,
    extension: FileExtension
  ): void {
    this.fileService
      .getFileData(fileId, '*', FileResponseType.BLOB)
      .pipe(takeUntil(this.compUnsubscribe$))
      .subscribe(data =>
        this.fileService.downloadFileByBlob(
          data as Blob,
          `${fileName}.${extension}`
        )
      );
  }

  private openLink(target: LinkTargetType): void {
    if (target !== LinkTargetType.SELF) {
      window.open(this.appLinkActionHandler, target);

      return;
    }

    let state = {};

    if (this.keywords) {
      state = { ...state, keys: this.keywords };
    }

    if (this.description?.length > 0) {
      state = {
        ...state,
        description: this.description,
        currentUrl: this.router.url,
      };
    }

    if (this.id) {
      state = { ...state, id: this.id };
    }

    this.router.navigateByUrl(
      this.appLinkActionHandler.replace(window.origin, ''),
      { state }
    );
  }
}
