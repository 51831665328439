import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AuthMsalService } from '@app/auth-msal/services/auth-msal.service';
import { NotificationService } from '@app/notification/services/notification.service';
import { MyProfileRoute } from '@app/profile/models/profile.models';
import {
  getDefaultFilterOption,
  getSearchFilterOptions,
} from '@app/search/constants/filter-options.constant';
import { BroadcasterService } from '@avenir-client-web/broadcaster';
import { APP_CONFIG } from '@avenir-client-web/models';
import {
  FilterOptionValue,
  SearchQueryOption,
} from '@avenir-client-web/search';
import { EventKeys } from '@avenir-client-web/signalr';
import { CredentialsService } from '@core/authentication/credentials.service';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { User } from '@core/models/profile.model';
import { TenantRole } from '@core/models/role.model';
import { UserPermission } from '@core/services/permission.model';
import { PermissionService } from '@core/services/permission.service';
import { ProfileService } from '@core/services/profile.service';
import { AppContentService } from '@core/services/static/app-content.service';
import { GlobalNotificationService } from '@shared/services/global-notification.service';
import { MenuItem, PrimeIcons } from 'primeng/api';
import {
  filter,
  filter as filterOperator,
  map,
  merge,
  mergeMap,
  Observable,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('searchTool') searchElement: ElementRef;

  profile: User = this.profileService.profile;

  items: MenuItem[];

  roleItems: MenuItem[];

  searchFilterOptions = getSearchFilterOptions(this.config);

  hideTopBarSearch$: Observable<boolean>;

  avatarUrl$: Observable<SafeUrl>;

  isSearchActive = false;

  appRootRoute = AppRootRoute;

  filter: FilterOptionValue;

  hasUnread$: Observable<boolean>;

  featureToggle = this.config.featureToggle;

  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    @Inject(APP_CONFIG) readonly config: any,
    private readonly authMsalService: AuthMsalService,
    private readonly credentialsService: CredentialsService,
    private readonly permissionService: PermissionService,
    private readonly router: Router,
    private readonly profileService: ProfileService,
    private readonly notificationService: NotificationService,
    private readonly broadcaster: BroadcasterService,
    private readonly globalNotificationService: GlobalNotificationService
  ) {
    this.setTopBarSearchState();
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: ElementRef): void {
    const clickedInside =
      this.searchElement?.nativeElement?.contains(targetElement);

    this.isSearchActive = clickedInside;
  }

  ngOnInit(): void {
    this.setMenuItems();
    this.getAvatarUrl();
    this.checkUnread();
    this.handleNotificationToast();

    this.permissionService.roleDummyChanges$.subscribe(() => {
      this.setMenuItems();
    });
  }

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  navigateToSearchResultsPage({ keyword }: SearchQueryOption): void {
    const content = AppContentService.getCurrentContent();

    this.filter = getDefaultFilterOption(
      this.searchFilterOptions,
      content.contentType,
      this.filter
    );

    this.router.navigate([AppRootRoute.SEARCH], {
      queryParams: {
        keyword,
        filter: this.filter,
      },
      state: {
        isShownFilterMsg: true,
      },
    });
  }

  onBellClick(): void {
    const shouldRefreshNotificationPage =
      this.router.url.split('/').pop() === AppRootRoute.NOTIFICATIONS;

    shouldRefreshNotificationPage &&
      this.globalNotificationService.isCurrentPage$.next();
  }

  private handleNotificationToast(): void {
    if (!this.featureToggle.tickets) return;
    this.authMsalService.loginSuccess$
      .pipe(mergeMap(() => this.notificationService.checkUnread()))
      .pipe(filter(Boolean), takeUntil(this.compUnsubscribe$))
      .subscribe(() => this.globalNotificationService.showNotificationToast());
  }

  private checkUnread(): void {
    if (!this.featureToggle.tickets) return;
    this.hasUnread$ = merge(
      this.notificationService.checkUnread(),
      this.broadcaster
        .on(EventKeys.GLOBAL.BELL_INDICATOR)
        .pipe(map(count => !!count))
    ).pipe(takeUntil(this.compUnsubscribe$));
  }

  private getAvatarUrl(): void {
    this.avatarUrl$ = this.profileService
      .getAvatarUrl(this.profile.aadId)
      .pipe(takeUntil(this.compUnsubscribe$));
  }

  private setTopBarSearchState(): void {
    this.hideTopBarSearch$ = this.router.events.pipe(
      filterOperator(event => event instanceof NavigationEnd),
      map(event => (event as NavigationEnd).url),
      startWith(this.router.url),
      map(url => url.includes(`/${AppRootRoute.SEARCH}`)),
      takeUntil(this.compUnsubscribe$)
    );
  }

  private logout(): void {
    this.authMsalService.logout();
  }

  private switchRole(role: TenantRole): void {
    this.credentialsService.beforeChangeRole$.next(role);
    this.credentialsService.onRoleSwitched$.subscribe(() =>
      this.setMenuItems()
    );
  }

  private setMenuItems(): void {
    const { role: currentRole } = this.credentialsService.getCredentials();

    const { userPermissions } = this.permissionService;

    this.roleItems = this.getRoleSubMenuItems(userPermissions, currentRole);

    this.items = [
      {
        label: $localize`menuItems.editProfile`,
        icon: PrimeIcons.COG,
        command: () => this.goToProfile(),
      },
      {
        label: $localize`menuItems.logout`,
        icon: PrimeIcons.SIGN_OUT,
        command: () => this.logout(),
      },
    ];
  }

  private goToProfile(): void {
    this.router.navigate([AppRootRoute.PROFILE, MyProfileRoute]);
  }

  private getRoleSubMenuItems(
    userPermissions: UserPermission[],
    currentRole: TenantRole
  ): MenuItem[] {
    return userPermissions.map(({ role }) => ({
      label: role.name,
      command: () => currentRole.id !== role.id && this.switchRole(role),
      styleClass: currentRole.id !== role.id ? '' : 'highlight',
    }));
  }
}
