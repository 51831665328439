import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserActiveGuard } from '@app/auth-msal/guards/user-active.guard';
import { UserFeatureGuard } from '@app/auth-msal/guards/user-feature.guard';
import { DummyComponent } from '@app/dummy/dummy.component';
import { ShellComponent } from '@app/shell/shell.component';
import { MsalGuard } from '@azure/msal-angular';
import { menu } from '@core/constants/menu-items.constant';
import { SigningInComponent } from '@core/containers/signing-in/signing-in.component';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { Permission } from '@core/services/permission.model';
import { ResetContentGuard } from './auth-msal/guards/reset-content.guard';

const shellRoutes: Routes = [
  {
    path: 'change-role/:roleId',
    component: DummyComponent,
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: 'delete-file/:fileId',
    component: DummyComponent,
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: AppRootRoute.HOME,
    loadChildren: () =>
      import('./home/home.module').then(m => {
        return m.HomeModule;
      }),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: AppRootRoute.ADMIN_PROCESSES,
    loadChildren: () =>
      import('./processes/processes.module').then(m => m.ProcessesModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.WRITE,
    },
  },
  {
    path: AppRootRoute.ADMIN_SEARCH_KEYWORDS,
    loadChildren: () =>
      import('./search-keyword/search-keyword.module').then(
        m => m.SearchKeywordModule
      ),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    canDeactivate: [ResetContentGuard],
    data: {
      permission: Permission.ADMIN,
    },
  },
  {
    path: AppRootRoute.ADMIN_LANGUAGES,
    loadChildren: () =>
      import('./tenant-language/tenant-language.module').then(
        m => m.TenantLanguageModule
      ),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    canDeactivate: [ResetContentGuard],
    data: {
      permission: Permission.ADMIN,
    },
  },
  {
    path: AppRootRoute.CONTENT_CATEGORIES,
    loadChildren: () =>
      import('./content-category/content-category.module').then(
        m => m.ContentCategoryModule
      ),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    canDeactivate: [ResetContentGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: AppRootRoute.ADMIN_CONTENT_CATEGORIES,
    loadChildren: () =>
      import('./content-category/content-category-routing.module').then(
        m => m.ContentCategoryAdminRoutingModule
      ),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    canDeactivate: [ResetContentGuard],
    data: {
      permission: Permission.WRITE,
    },
  },
  {
    path: AppRootRoute.ADMIN_FILES,
    loadChildren: () =>
      import('./tenant-file/tenant-file.module').then(m => m.TenantFileModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    canDeactivate: [ResetContentGuard],
    data: {
      permission: Permission.WRITE,
    },
  },
  {
    path: AppRootRoute.NEWS,
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: AppRootRoute.ADMIN_NEWS,
    loadChildren: () =>
      import('./news/news-routing.module').then(m => m.NewsAdminRoutingModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.WRITE,
    },
  },
  {
    path: AppRootRoute.WIKIS,
    loadChildren: () =>
      import('./wiki-page/wiki-page.module').then(m => m.WikiPageModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: AppRootRoute.ADMIN_WIKIS,
    loadChildren: () =>
      import('./wiki-page/wiki-page-routing.module').then(
        m => m.WikiPageAdminRoutingModule
      ),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.WRITE,
    },
  },
  {
    path: AppRootRoute.ADMIN_QUICKLINKS,
    loadChildren: () =>
      import('./quicklink/quicklink.module').then(m => m.QuicklinkModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.WRITE,
    },
  },
  {
    path: AppRootRoute.TICKETS,
    loadChildren: () =>
      import('./ticket/ticket.module').then(m => m.TicketModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: menu.ticketOverview.state.permission,
      rootRoute: AppRootRoute.TICKETS,
    },
  },
  {
    path: AppRootRoute.ADMIN_TICKETS,
    loadChildren: () =>
      import('./ticket/ticket.module').then(m => m.TicketAdminModule),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: menu.ticketManagement.state.permission,
      rootRoute: AppRootRoute.ADMIN_TICKETS,
    },
  },
  {
    path: AppRootRoute.NOTIFICATIONS,
    loadChildren: () =>
      import('./notification/notification.module').then(
        m => m.NotificationModule
      ),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: AppRootRoute.PROFILE,
    loadChildren: () =>
      import('./profile/profile.module').then(m => {
        return m.ProfileModule;
      }),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: menu.dashboard.state.permission,
      type: AppRootRoute.PROFILE,
    },
  },
  {
    path: AppRootRoute.SEARCH,
    loadChildren: () =>
      import('./search/search.module').then(m => {
        return m.SearchModule;
      }),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
      type: AppRootRoute.SEARCH,
    },
  },
  {
    path: AppRootRoute.ERRORS,
    loadChildren: () =>
      import('./generic-page/generic-page.module').then(m => {
        return m.GenericPageModule;
      }),
    canLoad: [MsalGuard, UserFeatureGuard],
    canActivate: [MsalGuard, UserFeatureGuard],
    data: {
      permission: Permission.READ,
    },
  },
  {
    path: '',
    redirectTo: `/${AppRootRoute.HOME}`,
    pathMatch: 'full',
  },
];

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: shellRoutes,
  },
  {
    path: AppRootRoute.DOCUMENT,
    loadChildren: () =>
      import('./document/document.module').then(m => {
        return m.DocumentModule;
      }),
    canLoad: [MsalGuard],
    canActivate: [MsalGuard],
  },
  {
    path: `${AppRootRoute.SIGN_IN}`,
    component: SigningInComponent,
    canLoad: [MsalGuard, UserActiveGuard],
    canActivate: [MsalGuard, UserActiveGuard],
  },
  {
    path: '**',
    redirectTo: `/${AppRootRoute.ERRORS}/${AppRootRoute.FORBIDDEN}`,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      canceledNavigationResolution: 'computed',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
